<template>

<!-- component -->
<div class="p-0">
    <div class="bg-white p-8 rounded-lg shadow-xl py-8 mt-12">
        <h3 class=" ">Documentação - Inteligência Artificial</h3>
        <p class="text-gray-600 text-sm mt-2">Aprimore seus conhecientos para construir um atendimento de IA adequado para sua operação.</p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 xl:gap-12 px-2 pb-12 xl:px-12 mt-4">
            <div class="flex space-x-8 mt-8">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                        <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
                    </svg>
                </div>
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Chatbot Duotalk IA</h4>
                    <p class="text-gray-600 my-2">O Duotalk é um sistema de atendimento automatizado que utiliza a inteligência artificial do ChatGPT para fornecer respostas rápidas e personalizadas com base em uma base de conhecimento definida. Essa documentação visa esclarecer como o sistema funciona, suas limitações e como os clientes podem maximizar sua eficiência por meio de um prompt de atendimento adequado.</p>
                    <p  class="text-blue-600 hover:text-blue-800 hover:underline capitalize" ><router-link :to="{ name: 'chatbot-ia-duotalk' }">Continue lendo</router-link></p>
                </div>
            </div>
            
            <div class="flex space-x-8 mt-8">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                    </svg>
                </div>
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Como funciona a base de conhecimento?</h4>
                    <p class="text-gray-600 my-2">A base de conhecimento é o conjunto de informações que o chatbot utiliza para responder às perguntas dos usuários. Ela pode ser composta por documentos de diversos formatos, como PDF, TXT, DOC e DOCX, com um limite de 100 arquivos de 50mb.</p>
                    <a href="#" class="text-blue-600 hover:text-blue-800 hover:underline capitalize" title="Continue lendo">Continue lendo</a>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"></path>
                        </svg>
                </div>
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Como estruturar o PROMPT de atendimento?</h4>
                    <p class="text-gray-600 my-2">Um ponto fundamental para o sucesso do chatbot é a criação de um prompt de atendimento bem estruturado. O prompt serve como uma "instrução" que orienta a IA sobre como se comportar, o que priorizar e até que ponto utilizar as informações da base de conhecimento.</p>
                    <a href="#" class="text-blue-600 hover:text-blue-800 hover:underline capitalize" title="Continue lendo">Continue lendo</a>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"></path>
                    </svg>
                </div>
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Configurações avançadas</h4>
                    <p class="text-gray-600 my-2">As configurações avançadas são realizadas diretamente no Duotalk para definir a transferência do atendimento com a I.A para o humano, atribuindo para o departamento correto de acordo com o contexto da conversa.</p>
                    <a href="#" class="text-blue-600 hover:text-blue-800 hover:underline capitalize" title="Continue lendo">Continue lendo</a>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"></path>
                    </svg>
                </div>
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Limitações do ChatGPT</h4>
                    <p class="text-gray-600 my-2">
O ChatGPT, em atendimento de bots, tem limitações como dificuldade em interpretar imagens/tabelas complexas, erros em cálculos avançados e variações nas respostas geradas. Além disso, pode criar respostas imprecisas ("alucinações") e não é ideal para fluxos rígidos, pois seu foco é linguagem generativa.</p>
                    <a href="#" class="text-blue-600 hover:text-blue-800 hover:underline capitalize" title="Continue lendo">Continue lendo</a>
                </div>
            </div>

            <div class="flex space-x-8 mt-8">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z"></path>
                    </svg>
                </div>
                <div>
                    <h4 class="text-xl font-bold text-gray-700">Exemplo de PROMPTs prontos</h4>
                    <p class="text-gray-600 my-2">Aqui estão alguns exemplos de prompts otimizados para atendimento com IA na Duotalk. Eles são projetados para oferecer respostas eficientes, personalizadas e integradas a dados externos, melhorando a experiência do cliente. Utilize esses prompts como base para configurar o seu chatbot.</p>
                    <a href="#" class="text-blue-600 hover:text-blue-800 hover:underline capitalize" title="Continue lendo">Continue lendo</a>
                </div>
            </div>

            
        </div>


    </div>

    
</div>



</template>


  
  <script>
  export default {
    name: 'ChatbotAIFaq',
    data() {
    return {
      // Lista de perguntas e respostas, o primeiro item já vem com `active: true`
      faqs: [
        { question: "O que é Duotalk?", answer: "Duotalk é uma plataforma que oferece soluções de atendimento automatizado.", active: true },
        { question: "Como configurar a inteligência artificial?", answer: "Você pode configurar a inteligência artificial seguindo as instruções no nosso guia.", active: false },
        { question: "Quais são os planos disponíveis?", answer: "Os planos variam de acordo com o número de usuários e as funcionalidades necessárias.", active: false },
      ]
    };
  },
  methods: {
    // Alterna a visibilidade da resposta
    toggleFaq(index) {
      this.faqs[index].active = !this.faqs[index].active;
    }
  }
};

</script>


<style scoped>
.faq-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-item h3 {
  cursor: pointer;
  padding: 10px;
  background-color: #f2f2f2;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

.faq-item h3:hover {
  background-color: #e0e0e0;
}

.faq-item h3.active {
  background-color: #d0d0d0;
}

.faq-item p {
  margin: 10px 0;
  padding: 10px;
  background-color: #fafafa;
  border-left: 5px solid #cccccc;
}
</style>
