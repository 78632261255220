import { render, staticRenderFns } from "./ChatbotAIFaq.vue?vue&type=template&id=0685b0ee&scoped=true"
import script from "./ChatbotAIFaq.vue?vue&type=script&lang=js"
export * from "./ChatbotAIFaq.vue?vue&type=script&lang=js"
import style0 from "./ChatbotAIFaq.vue?vue&type=style&index=0&id=0685b0ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0685b0ee",
  null
  
)

export default component.exports